<template>
  <div class="box">
    <div class="content">
      <div class="constItem" v-for="item in list" :key="item.articleId">
        <div class="img" @click="goDetail(item.articleId)">
          <el-image
            style="width: 100%; height: 100%; object-fit: cover"
            :src="item.imageUrl"
            fit="cover"
          ></el-image>
        </div>
        <div class="top">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.brief }}</div>
        </div>
        <div class="center">
          <div class="likeItem" @click="likeThis(item.articleId, 2)">
            <img
              v-if="item.isFavor == 1"
              style="width: 24px; height: 24px; margin-right: 6px"
              src="./images/Islike.png"
            />
            <img
              v-else
              style="width: 24px; height: 24px; margin-right: 6px"
              src="./images/like.png"
            />
            <span> {{ item.favorNum }}</span>
          </div>
          <div class="likeItem" @click="gotoCreate(item.articleId)">
            <img
              style="width: 24px; height: 24px; margin-right: 6px"
              src="./images/write.png"
            />
            <span> {{ item.renewNum }}</span>
          </div>
          <div class="likeItem" @click="likeThis(item.articleId, 1)">
            <img
              v-if="item.isCollect == 1"
              style="width: 24px; height: 24px; margin-right: 6px"
              src="./images/collect.png"
            />
            <img
              v-else
              style="width: 24px; height: 24px; margin-right: 6px"
              src="./images/collect.png"
            />
            <span>{{ item.collectNum }}</span>
          </div>
        </div>
        <div class="user">
          <el-avatar shape="square" size="medium" :src="item.authorHeadImage" />
          <span class="nickName">{{ item.authorName }}</span>
        </div>
      </div>
    </div>
    <div class="mosaic" @click="addList">
      <i class="el-icon-arrow-down"></i>
      <span>继续显示</span>
    </div>
  </div>
</template>

<script>
import { search, update } from "@/api/article";
import { mapState } from "vuex";
export default {
  data() {
    return {
      list: [],
      queryParams: {
        currentPage: 0,
        pageSize: 8,
        type: 2,
      },
    };
  },
  created() {
    this.$store.dispatch("getHomeInfo").then((res) => {
      this.list = res;
    });
  },
  mounted() {
    // this.getList();
    this.$bus.$on("homeSearch", (val) => {
      this.getList(val);
    });
  },
  methods: {
    gotoCreate(id) {
      // 判断是否登录
      if (!this.accountId) {
        return this.$message("请先登录");
      }
      // 判断是否有昵称
      if (this.userInfo.nickName) {
        // 存文章id
        this.$store.commit("SETAGOID", id);
        this.$router.push({
          path: "/Create",
          // 存创作类型
          query: {
            type: 2,
          },
        });
      } else {
        this.$prompt("请输入昵称", "你需要先设置一个昵称:", {
          confirmButtonText: "提交",
          inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
          inputErrorMessage: "昵称格式不符合要求，请修改后提交",
        }).then(({ value }) => {
          let param = {
            accountId: this.accountId,
            userName: value,
          };
          modifyInfo(param).then(({ code }) => {
            if (code == 1000) {
              this.$store.dispatch("getUserInfo");
            }
          });
        });
      }
    },
    addList() {
      this.queryParams.pageSize += 4;
      this.getList();
    },
    getList(val) {
      let param = {
        keyword: val,
        ...this.queryParams,
      };
      search(param).then(({ code, data }) => {
        if (code == 1000) {
          this.list = data;
        }
      });
    },
    // 跳转详情
    goDetail(id) {
      // 存文章id
      this.$store.commit("SETAGOID", id);
      this.$router.push({
        path: "/Details",
      });
    },
    //点赞和收藏
    likeThis(id, type) {
      const param = {
        accountId: this.accountId,
        articleId: id,
        type,
      };
      update(param).then(({ code, msg }) => {
        if (code == 1000) {
          this.getList();
        }
      });
    },
  },

  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style scoped lang="less">
.box {
  width: 1200px;
  margin: 20px auto;
  min-height: 60vh;
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .constItem {
      box-sizing: border-box;
      width: 23%;
      height: 360px;
      margin: 24px 10px;

      .img {
        width: 100%;
        height: 200px;
        cursor: pointer;
      }
      .top {
        width: 100%;
        box-sizing: border-box;
        padding: 12px 8px;
        background: #131418;
        .title {
          font-size: 18px;
          height: 30px;
        }
        .text {
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
          -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
          -webkit-line-clamp: 3; // 显示两行
          overflow: hidden;
          color: #646464;
          line-height: 22px;
        }
      }
      .center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 10px;
        font-size: 12px;
        color: #646464;
        .likeItem {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      .user {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 4px;
        .nickName {
          margin-left: 16px;
        }
      }
    }
    .constItem:hover {
      border: 4px solid #01e39b;
    }
  }
  .mosaic {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 0 10px 10px rgba(6, 7, 12, 0.2);
    background-image: radial-gradient(
      ellipse at 50% 50%,
      rgba(1, 227, 155, 0.5) 3%,
      rgba(1, 227, 155, 0.3) 20%,
      rgba(6, 7, 12, 0.3) 60%
    );
    span {
      margin-left: 10px;
    }
  }
}
</style>
