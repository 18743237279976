<template>
  <div>
    <SearchLink ></SearchLink>
    <Content></Content>

  </div>
</template>
<script>
//引入子组件
//发现三级联动在search模块中也使用，咱们将三级联动的组件注册为全局组件
//下面引入的是局部组件：定义、引入、注册、使用
import SearchLink from "@/pages/Home/SearchLink";
import Content from "@/pages/Home/Content";

//引入辅助函数
// import {mapState} from 'vuex';
export default {
  name: "Home",
  components: {
    SearchLink,
    Content,
  },

  // //计算属性
  // computed:{
  //   ...mapState({
  //      floorList:state=>state.home.floorList
  //   })
  // }
};
</script>

<style scoped>
</style>
